// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".privacy_privacy__hdPP4{\n    padding-top: 10em;\n    padding-bottom: 5em;\n    display: flex;\n    width: 90%;\n    margin: auto;\n    flex-direction: column;\n}\n.privacy_privacy__hdPP4 h1{\n    text-align: center;\n    color: #00aeef;\n    font-size: 2rem;\n    font-weight: 700;\n    margin-bottom: 1em !important;\n}", "",{"version":3,"sources":["webpack://src/StyleSheets/Privacy/privacy.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC","sourcesContent":[".privacy{\n    padding-top: 10em;\n    padding-bottom: 5em;\n    display: flex;\n    width: 90%;\n    margin: auto;\n    flex-direction: column;\n}\n.privacy h1{\n    text-align: center;\n    color: #00aeef;\n    font-size: 2rem;\n    font-weight: 700;\n    margin-bottom: 1em !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy": "privacy_privacy__hdPP4"
};
export default ___CSS_LOADER_EXPORT___;
