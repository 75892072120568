// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "  \n  .subjectcard_subject-card-body__1fqip {\n    background: #FFF;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .subjectcard_subject-heading__b4mg7{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #000;\n    color: #FFF;\n    margin: 10px 0px;\n    height: 15vh;\n    z-index: 0;\n  }\n  .subjectcard_subject-card-content__1CdS5{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin: 80px 0px;\n  }", "",{"version":3,"sources":["webpack://src/StyleSheets/Test Series/subjectcard.module.css"],"names":[],"mappings":";EACE;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,UAAU;EACZ;EACA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,gBAAgB;EAClB","sourcesContent":["  \n  .subject-card-body {\n    background: #FFF;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .subject-heading{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #000;\n    color: #FFF;\n    margin: 10px 0px;\n    height: 15vh;\n    z-index: 0;\n  }\n  .subject-card-content{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin: 80px 0px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subject-card-body": "subjectcard_subject-card-body__1fqip",
	"subject-heading": "subjectcard_subject-heading__b4mg7",
	"subject-card-content": "subjectcard_subject-card-content__1CdS5"
};
export default ___CSS_LOADER_EXPORT___;
