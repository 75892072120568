// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_card__yGGsI{\n    color: white;\n    display: flex;\n    float: left;\n    padding: 12px;\n    cursor: pointer;\n  }\n  .card_inner-card__2K7YL{\n    position:relative;\n    width: 100%;\n    background-color: #A9C9FF;\n    background-image: linear-gradient(45deg, #A9C9FF 0%, #FFBBEC 100%);\n    border-radius: 15px;\n    padding: 24px;\n    box-shadow: 0 10px 20px rgba(169, 201, 255, .4);\n    transition: 0.3s ease-in-out;\n    color: #000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .card_inner-card__2K7YL:hover{\n    transform: scale(1.02);\n    box-shadow: 0 15px 20px rgba(169, 201, 255, .6);\n  }", "",{"version":3,"sources":["webpack://src/StyleSheets/Test Series/card.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IAKZ,aAAa;IACb,WAAW;IACX,aAAa;IACb,eAAe;EACjB;EACA;IACE,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,kEAAkE;IAClE,mBAAmB;IACnB,aAAa;IACb,+CAA+C;IAC/C,4BAA4B;IAC5B,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,sBAAsB;IACtB,+CAA+C;EACjD","sourcesContent":[".card{\n    color: white;\n    display: -webkit-box;\n    display: -moz-box;\n    display: -ms-flexbox;\n    display: -webkit-flex;\n    display: flex;\n    float: left;\n    padding: 12px;\n    cursor: pointer;\n  }\n  .inner-card{\n    position:relative;\n    width: 100%;\n    background-color: #A9C9FF;\n    background-image: linear-gradient(45deg, #A9C9FF 0%, #FFBBEC 100%);\n    border-radius: 15px;\n    padding: 24px;\n    box-shadow: 0 10px 20px rgba(169, 201, 255, .4);\n    transition: 0.3s ease-in-out;\n    color: #000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .inner-card:hover{\n    transform: scale(1.02);\n    box-shadow: 0 15px 20px rgba(169, 201, 255, .6);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_card__yGGsI",
	"inner-card": "card_inner-card__2K7YL"
};
export default ___CSS_LOADER_EXPORT___;
