// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".question_header__1qLi3{\n    height: 10vh;\n    background-color: #FFF;\n    text-align: right;\n    margin: 10px 20px 0px 0px;\n}\n.question_content__3CoSk{\n    width: 100%;\n    height: 90vh;\n    position: fixed;\n    background-color: #EDEDED;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.question_inner-content__3L-my{\n    width: 90%;\n    height: 90%;\n    background-color: #FFF;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.question_inner-content__3L-my h2{\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n.question_inner-content__3L-my select{\n    width: 30%;\n    height: 8%;\n    font-size: 20px;\n}", "",{"version":3,"sources":["webpack://src/StyleSheets/admin/NewAdmin/question.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,UAAU;IACV,UAAU;IACV,eAAe;AACnB","sourcesContent":[".header{\n    height: 10vh;\n    background-color: #FFF;\n    text-align: right;\n    margin: 10px 20px 0px 0px;\n}\n.content{\n    width: 100%;\n    height: 90vh;\n    position: fixed;\n    background-color: #EDEDED;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.inner-content{\n    width: 90%;\n    height: 90%;\n    background-color: #FFF;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.inner-content h2{\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n.inner-content select{\n    width: 30%;\n    height: 8%;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "question_header__1qLi3",
	"content": "question_content__3CoSk",
	"inner-content": "question_inner-content__3L-my"
};
export default ___CSS_LOADER_EXPORT___;
