import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const Header = () => {
    return(
        <>
            <DesktopHeader />
            <MobileHeader />
        </>
    )
}

export default Header;